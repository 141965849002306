<template>
  <div id="app">
    <template v-if="showNav">
      <Header />
      <div id="two-columns">
        <Sidebar/>
        <div id="content">
          <router-view/>
        </div>
      </div>
    </template>
    <template>
      <router-view v-if="!showNav"/>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
export default {
  components: {
    Header,
    Sidebar
  },
  computed: {
    ...mapState(['userProfile']),
    showNav() {
      return Object.keys(this.userProfile).length > 1
    }
  }
}
</script>
