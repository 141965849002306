<template>
  <div class="content">
    <div class="content-area">
      <div class="posted-by">
        <div>{{ userProfile.name }}</div>
        <div>{{ userProfile.title }}</div>
        <div>
          <router-link :to="'/articles/new'" class="btn">Add Article</router-link>
        </div>
        </div>
        <form action="#" class="search-article">
            <div class="search-wrap">
                <div class="input-wrap">
                    <label class="label" for="search">
                        <i class="icon-search"></i>
                    </label>
                    <input type="search" v-model="search" placeholder="Search article">
                </div>
                <div class="right-block">
                    <selectCat :addcat="false" :options="cats" :default="'Select Category'" @input="displayToKey($event)" />
                </div>
            </div>
        </form>
        <div class="post-items" v-if="filtered.length">
            <div v-for="post in filtered" :key="post.id" class="post">
              <div class="inner">
                <h5>{{ post.userName }}</h5>
                <span>{{ post.createdOn | formatDate }}</span>
                <h6>
                    <router-link :to="'/articles/' + post.id">
                        {{ post.title }}
                    </router-link>
                </h6>
                <p>{{ post.content | trimLength(100) | removeHtml }}</p>
                <ul>
                    <li><a>comments {{ post.comments }}</a></li>
                    <li><a @click="likePost(post.id, post.likes)">likes {{ post.likes }}</a></li>
                    <li><router-link :to="'/articles/' + post.id">view full post</router-link></li>
                </ul>
              </div>
            </div>
        </div>
        <div v-else>
            <p class="no-results">There are currently no posts</p>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import selectCat from './components/selectCat'

export default {
  components: {
    selectCat
  },
  title: 'Articles',
  data() {
    return {
      fullPost: {},
      postComments: [],
      search: '',
      cat: '',
    }
  },
  computed: {
    ...mapState(['userProfile', 'posts', 'cats']),
    filtered() {
      let tempPosts = this.posts

      if (this.search != '' && this.search) {
        tempPosts = tempPosts.filter(item => item.title.toLowerCase().includes(this.search.toLowerCase()))
      }

      if (this.cat != '' && this.cat && this.cat != 'All') {
        tempPosts = tempPosts.filter(item => item.category.includes(this.cat))
      }

      return tempPosts
    }
  },
  methods: {
    likePost(id, likesCount) {
      this.$store.dispatch('likePost', { id, likesCount })
    },
    displayToKey(e) {
      this.cat = e.name

      if(e.name == 'all')
        this.cat = ''

      console.log(e.name)
    }
  }
}
</script>