<template>
  <header v-click-outside="hideSidebar" id="header">
    <div class="logo">
      <router-link to="/">
        <img src="/images/slack-logo.svg" alt="fe-tools">
      </router-link>
    </div>
    
    <div class="activities-holder" v-click-outside="closeDrop">
      <a href="#" @click.prevent="toggleMobileNavActive" class="mobile-nav">
        <span></span>
        <svg fill="#fff" viewBox="-122.9 121.1 105.9 61.9"><path d="M-63.2 180.3l43.5-43.5c1.7-1.7 2.7-4 2.7-6.5s-1-4.8-2.7-6.5c-1.7-1.7-4-2.7-6.5-2.7s-4.8 1-6.5 2.7L-69.9 161l-37.2-37.2c-1.7-1.7-4-2.7-6.5-2.7s-4.8 1-6.5 2.6c-1.9 1.8-2.8 4.2-2.8 6.6 0 2.3.9 4.6 2.6 6.5 11.4 11.5 41 41.2 43 43.3l.2.2c3.6 3.6 10.3 3.6 13.9 0z"></path></svg>
      </a>
      <a href="#" class="activities" @click="userDrop = !userDrop">
        <div class="user">
          <div class="avatar">
            <img :src="userProfile.image" alt="user">
          </div>
          <div class="name">{{ userProfile.name }}</div>
        </div>
        <span class="icon-dots">
        </span>
      </a>
      <div class="activities-drop" :class="{'active' : userDrop}">
        <ul>
          <li><a @click.prevent="logout()" href="#">Log Out</a></li>
          <li>
            <router-link to="/settings" @click.native="closeDrop">Setting</router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        userDrop: false,
      }
    },
    computed: {
      ...mapState(['userProfile'])
    },
    methods: {
      logout() {
        this.$store.dispatch('logout')
      },
      toggleMobileNavActive () {
        document.body.classList.toggle('nav-active')
      },
      hideSidebar() {

        document.body.classList.remove('nav-active')
      },
      closeDrop() {
        this.userDrop = false
      }
    }
  }
</script>