<template>
<div class="custom-select" :tabindex="tabindex" :clickable="clickable" :addcat="addcat">
    <span @click="toggleDrop" class="selected" :class="{ open: isDrop }">
        {{ selected }}
        <i v-if="clickable" :class="!isDrop ? 'icon-chevron-down' : 'icon-chevron-up'"></i>
    </span>
    <ul v-if="isDrop" class="filter_list">
        <li v-if="addcat"><input type="text" @click="isDrop = true" @keyup.enter="createCat" placeholder="Add Category" v-model.trim="val"></li>
        <li v-else @click="
          isDrop = false;
          selected = 'All';
          $emit('input', {name: 'all'})
        ">All</li>
        <li v-for="(option, i) of options" :key="i" @click="
          selected = option.name;
          isDrop = false;
          $emit('input', option);
        ">{{ option.name }}</li>
    </ul>
</div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
        clickable: {
            type: Boolean,
            required: false,
            default: true
        },
        addcat: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            selected: this.default ?
                this.default :
                this.options.length > 0 ?
                this.options[0].name :
                null,
            isDrop: false,
            val: '',
        }
    },
    methods: {
        toggleDrop() {
            if(this.clickable) 
                this.isDrop = !this.isDrop
        },
        createCat() {
            this.$store.dispatch('createCat', {
                name: this.val,
            })
            this.val = ''
        },
    },
    mounted() {
        this.$emit('input', this.selected)
    }
}
</script>
