<template>
  <div class="content">
    <div class="error" v-if="errors">{{ errors }}</div>

    <div class="input-wrap">
      <input class="input" type="text" v-model.trim="snippet.title" placeholder="Title">
      <button @click="createSnippet()" class="btn">Create Mixins</button>
    </div>
    <div class="s-row mixins">
      <div class="s-col">
        <h3>Mixins Title</h3>
        <prism-editor class="my-editor" v-model="snippet.html" :highlight="highlighter" line-numbers></prism-editor>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import moment from 'moment'
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  
  export default {
    data() {
      return {
        snippet: {
          title: '',
          html: '',
          js: '',
          scss: '',
        },
        errors: null
      }
    },
    computed: {
      ...mapState(['userProfile', 'posts']),
    },
    methods: {
      highlighter(code) {
        return highlight(code, languages.js, "js");
      },
      createSnippet() {
        if(this.snippet.title || this.snippet.html || this.snippet.js || this.snippet.scss) {
          this.$store.dispatch('createSnippet', { html: this.snippet.html, scss: this.snippet.scss, js: this.snippet.js, title: this.snippet.title })
          this.snippet.title = ''
          this.snippet.html = ''
          this.snippet.js = ''
          this.snippet.scss = ''
        } else {
          this.errors = "Please fill all fields"
        }
      },
    },
    filters: {
      formatDate(val) {
        if (!val) { return '-' }
        
        let date = val.toDate()
        return moment(date).fromNow()
      },
    }
  }
</script>
