<template>
  <div class="content">

    <div class="max-800">
        <h1>Welcome to Front-end Tools.</h1>
        <h2>Recent Snippets</h2>
        <div class="snippets-item" v-if="filteredSnippets.length">
          <div class="snippet-item" v-for="snippet in filteredSnippets" :key="snippet.id" ><router-link :to="'/snippet/'+ snippet.id">{{ snippet.title }}</router-link></div>
          <div class="btn-holder">
            <router-link class="btn" to="/snippet">Create Snippet</router-link>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    title: 'Dashboard',
    computed: {
      ...mapState(['snippets']),
      filteredSnippets() {
        return this.snippets.slice(0,6);
      }
    }
  }
</script>
